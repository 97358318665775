<script>
    export default {
    name: 'ChatRoomTuHaoAnimation',
    components: {

    },
    data() {
        return {
            tuhao:{
                duration:3000,
                show:true,
            },
        }            
    },
    props: [

    ],
    inject: [

    ],
    methods: {
        
    },    
    provide:function(){
        
    },
    mounted() {
        var that=this;
        setTimeout(function(){
            that.tuhao.show=false
        },that.tuhao.duration);
    },
    computed:{

    },
    watch:{
        
    },
    beforeDestroy(){
        
    },
};
</script>
<template>
    <img class="tuhao" :src="require('@@/assets/images/chatroom/gif/tuhao.gif')+'?rand='+new Date().getTime()+Math.random()" v-if="tuhao.show" />
</template>
<style lang='scss' scoped>

</style>